import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Text from "../../../components/Text"
import Image from "../../../components/Image"
import Heading from "../../../components/Heading"
import Container from "../../../components/UI/Container"
import FeatureBlock from "../../../components/FeatureBlock"
import GlideCarousel from "../../../components/GlideCarousel"
import GlideSlide from "../../../components/GlideCarousel/glideSlide"
import SectionWrapper, { CarouseWrapper, TextWrapper } from "./appSlider.style"

const AppSlider = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        appSlider {
          title
          description
          features {
            id
            icon {
              publicURL
            }

            title
            description
          }
          carousel {
            id
            title
            image {
              publicURL
            }
          }
        }
      }
    }
  `)
  const {
    title,
    description,
    features,
    carousel,
  } = data.appModernJson.appSlider

  const glideOptions = {
    type: "carousel",
    gap: 0,
    autoplay: 5000,
    perView: 1,
    animationDuration: 700,
  }

  return (
    <SectionWrapper  id="onboard">
      <Container>
        <CarouseWrapper>
          <GlideCarousel
            bullets={true}
            controls={false}
            numberOfBullets={3}
            options={glideOptions}
            carouselSelector="appFeatureSlider"
          >
            <Fragment>
              {carousel.map(item => (
                <GlideSlide key={`feature-side--key${item.id}`}>
                  <Image src={item.image.publicURL} alt={item.title} />
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </CarouseWrapper>
        <TextWrapper>
          <Heading content={title} />
          <Text content={description} />
          {features.map(item => (
            <FeatureBlock
              key={`app-feature--key${item.id}`}
              iconPosition="left"
              icon={<img src={item.icon.publicURL} alt={item.title} />}
              title={<Heading as="h3" content={item.title} />}
              description={<Text content={item.description} />}
            />
          ))}
        </TextWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default AppSlider
