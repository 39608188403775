/* eslint-disable */
import React from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Icon } from "react-icons-kit"
import { playCircle } from "react-icons-kit/fa/playCircle"
import { openModal, closeModal } from "@redq/reuse-modal"
// import Text from 'common/src/components/Text';
import Text from "../../../components/Text"
import Image from "../../../components/Image"
import Button from "../../../components/Button"
import Heading from "../../../components/Heading"
import Rating from "../../../components/Rating"
import Container from "../../../components/UI/Container"
import style from './banner.module.css'
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from "./banner.style"

import microsoft from "../../../assets/image/appModern/envato-icon.png"
import bannerImg from "../../../assets/image/appModern/banner2.png"
import videoBanner1 from "../../../assets/image/appModern/google-play-button.png"
import videoBanner2 from "../../../assets/image/appModern/play-store-button.png"
import circleBorder from "../../../assets/image/appModern/shape.svg"
// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
)

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
)

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          link
          image {
            publicURL
          }
        }
      }
    }
  `)
  const { client } = data.appModernJson
  // modal handler
  const openLink = (url) => {
    window.open(url, '_blank');
  }
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          {/* <Fade up>
            <RatingInfo>
              <Rating rating={4} />
              4.9 of 5 By <img src={microsoft} alt="Microsoft" />
            </RatingInfo>
          </Fade> */}
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="A solution to your commuting problems"
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="A platform to solve commuting problems for your organization."
            />
          </Fade>
          <a href="https://www.facebook.com/razacarapp/posts/pfbid02QSKheKV8DYdFvNezKHKr9iQktSsj8mY96ahiU58vbSuuXXJASkhrsnfLRbozYoTKl">
          <CustomerWrapper>
            <ImageWrapper>
              <Image
                src="https://www.google.com/s2/favicons?sz=128&domain=google.com"
                alt="Google"
                className={style.companiesLogo}
              />
            </ImageWrapper>
            <Text className={style.googleText} content="Top-50 Semifinalist in the Google Solution Challenge of 2021" />
          </CustomerWrapper>
          </a>
          <VideoGroup>
            <img
              src={videoBanner1}
              onClick={openLink.bind(this, "https://play.google.com/store/apps/details?id=com.divdash.razacar")}
              alt="Play Store"
              className={style.imgSize}
            />
            <img
              src={videoBanner2}
              onClick={openLink.bind(this, "https://apps.apple.com/us/app/razacar/id6670546569")}
              alt="App Store"
              className={style.imgSize}
            />
          </VideoGroup>
        </BannerContent>
        <BannerImage>
          <Fade up delay={100}>
            <Image src={bannerImg} alt="Banner" />
          </Fade>
        </BannerImage>
      </Container>
      <CustomerWrapper>
        <Text className={style.txtSize} content="Our Users :" />
        <ImageWrapper>
          {client.map(item => (
            <a href={item.link} key={`client-key${item.id}`}>
            <Image
              
              src={item.image.publicURL}
              alt={item.title}
              className={style.companiesLogo}
            />
            </a>
          ))}
        </ImageWrapper>
      </CustomerWrapper>
      <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      />
    </BannerWrapper>
  )
}

export default Banner
