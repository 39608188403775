import React, { Fragment } from "react"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import { Icon } from "react-icons-kit"
import { mediaRecordOutline } from "react-icons-kit/typicons/mediaRecordOutline"
import { plus } from "react-icons-kit/typicons/plus"
import { starOutline } from "react-icons-kit/typicons/starOutline"
import Text from "../../../components/Text"
import Heading from "../../../components/Heading"
import Image from "../../../components/Image"
import Container from "../../../components/UI/Container"
import FeatureBlock from "../../../components/FeatureBlock"
import { SectionHeader } from "../appModern.style"
import SectionWrapper, { FeatureWrapper } from "./features.style"
import style from "../global.module.css"
import "./feature.css"
const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        features {
          slogan
          title
          items {
            id
            color
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `)
  const { slogan, title, items } = data.appModernJson.features

  return (
    <SectionWrapper id="features">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading className={style.myTextClr} as="h5" content={slogan} />
            <Heading content={title} />
          </Fade>
        </SectionHeader>
        <FeatureWrapper style={{ textAlign: "center" }}>
          {items.map((item: any) => (
            <Fade up delay={100 * item.id} key={`feature-key${item.id}`}>
              <FeatureBlock
                style={{ "--color": `${item.color}` }}
                icon={
                  <Fragment>
                    <Icon className="plus" icon={plus} />
                    <Icon className="circle" icon={mediaRecordOutline} />
                    <div className="featureImgStyle">
                      <Image
                        style={{
                          width: "90px",
                          height: "90px",
                          marginBottom: "13px",
                        }}
                        src={item.icon.publicURL}
                        alt={item.title}
                      />
                    </div>
                    <Icon className="star" icon={starOutline} />
                  </Fragment>
                }
                iconPosition="left"
                title={<Heading as="h3" content={item.title} />}
                description={<Text content={item.description} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default Features
