import React from "react"
import "./Contact.css"
const Contact = () => {
  return (
    <div className="mainContact" id="contact">
      <div className="my-container">
        <div className="textStyle">
          <h1>Don't see your institute?</h1>
          <p>Leave us a request and we'll get back to you!</p>
        </div>
        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdXzFS4kk1w0P16ceNGutfsuOqSYO9PQIRZAIH922vWHrgiZA/viewform?embedded=true" width="640" height="984" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
        <form action="https://docs.google.com/forms/d/e/1FAIpQLSdXzFS4kk1w0P16ceNGutfsuOqSYO9PQIRZAIH922vWHrgiZA/viewform?usp=pp_url&entry.1587582776=Name&entry.379584303=Organization&entry.417915698=Message">
          <label>Your Name</label>
          <input required type="text" id="name" name="entry.1587582776" />
          {/* <label>Email address</label>
          <input required type="email" id="email" name="entry.1045781291" /> */}
          <label>Organization Name</label>
          <input
            required
            type="text"
            id="organization"
            name="entry.379584303"
          />
          <label>Message</label>
          <textarea
            name="entry.417915698"
            rows={3}
            cols={70}
            id="subject"
            placeholder="Write a Message.."
            // style={{ height: "200px" }}
          ></textarea>

          <input type="submit" value="Submit"></input>
        </form>
      </div>
    </div>
  )
}
export default Contact
