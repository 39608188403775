import React from "react"
import PropTypes from "prop-types"
import { GlideSlideWrapper } from "./glide.style"

type Props = {
  children: any
}

// Glide Slide wrapper component
const GlideSlide: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <GlideSlideWrapper className="glide__slide">{children}</GlideSlideWrapper>
  )
}

GlideSlide.propTypes = {
  /** Children. */
  children: PropTypes.element,
}

export default GlideSlide
