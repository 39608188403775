import { ThemeProvider } from "styled-components"
import React from "react"
import { theme } from "../theme/appModern"
import SEO from "../components/seo"
import { ResetCSS } from "../assets/css/style"
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "../containers/AppModern/appModern.style"
import Sticky from "react-stickynode"
import Navbar from "../containers/AppModern/Navbar"
import Banner from "../containers/AppModern/Banner"
import Features from "../containers/AppModern/Features"
import AppSlider from "../containers/AppModern/AppSlider"
import DashboardFeatures from "../containers/AppModern/Dashboard"
import ProductSlide from "../containers/AppModern/ProductSlide"
import Contact from "./../containers/AppModern/Contact/Contact"
import Footer from "../containers/AppModern/Footer"
export default function home() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="razacar helps daily commuters find safer and affordable rides through our app." />
        <ResetCSS />
        <GlobalStyle />
        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar isTrue={true} />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Features />
            <AppSlider />
            <DashboardFeatures />
            <ProductSlide />
            <Contact />
          </ContentWrapper>
          <Footer isHomePage={true} />
        </AppWrapper>
      </>
    </ThemeProvider>
  )
}
