import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Box from "../../../components/Box"
import Text from "../../../components/Text"
import Image from "../../../components/Image"
import Heading from "../../../components/Heading"
import Button from "../../../components/Button/index.js"
import FeatureBlock from "../../../components/FeatureBlock"
import Container from "../../../components/UI/Container"
import Particles from "../Particle"
import DashboardWrapper, { DashboardObject } from "./dashboard.style"

import DashboardObject1 from "./../../../assets/image/appModern/dashboard.png"
import style from "./../global.module.css"
type DashboardProps = {
  row?: any
  col?: any
  title?: any
  btnStyle?: any
  description?: any
  contentStyle?: any
}
const DashboardSection: React.FC<DashboardProps> = ({
  row,
  col,
  title,
  btnStyle,
  description,
  // discountText,
  // discountAmount,
  // outlineBtnStyle,
}): JSX.Element => {
  const ButtonGroup = (): JSX.Element => (
    <Fragment>
      <Button className={style.myBtnClr} title="View Demo" {...btnStyle} />
    </Fragment>
  )
  return (
    <DashboardWrapper id="dashboard">
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <Heading
              className="subtitle"
              as="h5"
              content="Management Dashboard"
            />
            <FeatureBlock
              title={
                <Heading content="Find your organisation's stats." {...title} />
              }
              description={
                <Text
                  content="Use the dashboard to manage commuters under your organisation and learn from anonymous statistics to improve other logistics matters such as point assignment based on the rider heatmap."
                  {...description}
                />
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <DashboardObject>
        <div className="dashboardWrapper">
          <Image src={DashboardObject1} alt="DashboardObject1" />
        </div>
      </DashboardObject>
    </DashboardWrapper>
  )
}

DashboardSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
}

DashboardSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
    alignItems: "center",
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: [1, "70%", "50%", "45%"],
  },
  title: {
    fontSize: ["22px", "30px", "30px", "30px", "36px"],
    fontWeight: "700",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "15px", "15px", "20px", "25px"],
    lineHeight: "1.3",
    maxWidth: ["100%", "400px"],
  },
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.85",
    mb: "0",
  },
  btnStyle: {
    minWidth: ["120px", "120px", "120px", "156px"],
    fontSize: ["13px", "14px"],
    fontWeight: "500",
    colors: "primaryWithBg",
  },
}

export default DashboardSection
